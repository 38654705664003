import axios from "axios";
import store from "@/store";
import router from "@/router";

const config = {
    baseURL: `${process.env.VUE_APP_ROOT_API}`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 10000
}

const apiClient = axios.create(config)
apiClient.interceptors.request.use((config) => {
    const token = store.state?.user?.tokens?.access?.token
    console.log('AccessToken', token)
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})
apiClient.interceptors.response.use(response => {
    console.log("URL", response.config.url)
    console.log("Response", response)
    return response
}, error => {
    console.log("Response with error", error.response)

    if (error.response.status !== 401
        || error.response.status === 401 &&
        (error.config.url === '/auth/login' || error.config.url === '/auth/verify-email')) {
        return Promise.reject(error);
    }

    // TODO check if user is disabled
    console.log("URL", error.config.url)
    if (error.config.url === '/auth/refresh-tokens') {
        console.log("Refresh token failed")
        // TODO Test refresh expired
        store.commit('logout')
        router.push({ name: 'login' }).then();
        return Promise.reject(error);
    }

    return store.dispatch('refreshToken').then(tokens => {
        console.log('New Tokens', tokens)
        const config = error.config;
        config.headers['Authorization'] = `Bearer ${tokens?.access?.token}`;
        return new Promise((resolve, reject) => {
            apiClient.request(config).then(response => {
                console.log("Response of retry", response)
                resolve(response);
            }).catch((error) => {
                console.log("Response of retry with error", error)
                reject(error);
            })
        });
    })
        .catch((error) => {
            return Promise.reject(error);
        });

})

export default {
    isEmailTaken(email) {
        return apiClient.get(`/users?email=${email}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    login(email, password) {
        return apiClient.post('/auth/login', { email, password }).then(response => response.data)
    },
    register(userData) {
        return apiClient.post('/auth/register', userData).then(response => response.data)
    },
    verifyEmail(token) {
        return apiClient.post('/auth/verify-email', { token })
    },
    forgotPassword(email) {
        return apiClient.post('/auth/forgot-password', { email }).then(response => response.data)
    },
    resetPassword(token, password) {
        return apiClient.post('/auth/reset-password', { token, password })
    },
    logout(refreshToken) {
        return apiClient.post('/auth/logout', { refreshToken })
    },
    refreshToken(refreshToken) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            return response.data
        })
    },
    getUsers() {
        return apiClient.get('/users?limit=1000').then(response => {
            return response.data.results
        })
    },
    getUser(userId) {
        return apiClient.get(`/users/${userId}`).then(response => {
            return response.data
        })
    },
    saveUser(user) {
        return apiClient.post("/users", user).then(result => {
            return result.data
        })
    },
    updateUser(userData) {
        const data = { ...userData }
        const userId = data.id
        delete data.id
        delete data.isEmailVerified
        delete data.clusters
        data.avatarImage = data.avatarImage ? data.avatarImage.id : undefined
        console.log(userData)
        return apiClient.patch(`/users/${userId}`, data).then(result => {
            return result.data
        })
    },
    deleteUser(userId) {
        console.log("Delete user", userId)
        return apiClient.delete(`/users/${userId}`).then(response => {
            return response.data
        })
    },
    getPosts(categoryId) {
        console.log('getPosts', categoryId)
        return apiClient.get(`/posts?categoryId=${categoryId}`).then(response => {
            return response.data.results
        })
    },
    getPost(postId) {
        return apiClient.get(`/posts/${postId}`).then(response => {
            return response.data
        })
    },
    getEvent(eventId) {
        console.log("getEvent", eventId)
        return apiClient.get(`/events/${eventId}`).then(response => {
            return response.data
        })
    },
    getPostsByUserId(userId) {
        console.log('getPosts', userId)
        return apiClient.get(`/posts?type=message&userId=${userId}`).then(response => response.data.results)
    },
    getEventsByUserId(userId) {
        console.log('getEventsByUserId', userId)
        return apiClient.get(`/events?userId=${userId}`).then(response => response.data.results)
    },
    savePost(post) {
        console.log("Save post", post)
        return apiClient.post(`/posts`, {...post, type: 'message'}).then(response => {
            return response.data
        })
    },
    saveEvent(event) {
        console.log("Save events", event)
        return apiClient.post(`/events`, {...event, type: 'event'}).then(response => {
            return response.data
        })
    },
    updatePost(post) {
        console.log("Update post", post)
        let p = { ...post }
        delete p.id
        delete p.clusters
        return apiClient.patch(`/posts/${post.id}`, p).then(response => {
            return response.data
        })
    },
    updateEvent(event) {
        console.log("Update post", event)
        let p = { ...event }
        delete p.id
        delete p.clusters
        return apiClient.patch(`/events/${event.id}`, p).then(response => {
            return response.data
        })
    },
    deletePost(postId) {
        console.log("Delete post", postId)
        return apiClient.delete(`/posts/${postId}`).then(response => {
            return response.data
        })
    },
    getPostCategories() {
        return apiClient.get('/postCategories').then(result => result.data.results)
    },
    getCompanies() {
        return apiClient.get('/companies?limit=1000').then(result => result.data.results)
    },
    getCompanyByUserId(userId) {
        return apiClient.get(`/users/${userId}/company`).then(result => result.data)
            .catch(error => {
                if(error.response.status === 404) {
                    return null
                }
            })
    },
    getCompany(companyId) {
        return apiClient.get(`/companies/${companyId}`).then(result => result.data)
    },
    getCompanyPosts(companyId) {
        return apiClient.get(`/companies/${companyId}/posts`).then(response => response.data.results)
    },

    saveCompany(userId, companyData) {
        return apiClient.post(`/users/${userId}/company`, companyData).then(result => result.data)
    },
    updateCompanyByUserId(userId, companyData) {
        const c = {...companyData }
        delete c.id
        delete c.location
        delete c.clusters
        return apiClient.patch(`/users/${userId}/company`, c).then(result => result.data)
    },
    getUserClusters(userId) {
       return apiClient.get(`/users/${userId}/clusters`).then(response => response.data.results)
    },
    getCluster(clusterId) {
        return apiClient.get(`/clusters/${clusterId}`).then(response => response.data)
    },
    getClusters() {
        return apiClient.get('/clusters?limit=1000').then(response => response.data.results)
    },
    saveCluster(clusterData) {
        return apiClient.post('/clusters', clusterData).then(response => response.data)
    },
    updateCluster(clusterData) {
        const data = { ...clusterData }
        const clusterId = data.id
        delete data.id
        return apiClient.patch(`/clusters/${clusterId}`, data).then(response => response.data)
    },
    deleteCluster(clusterId) {
        return apiClient.delete(`/clusters/${clusterId}`).then(response => response.data)
    },
    getClusterPosts(clusterId, type = null, categoryId = null ) {
        return apiClient.get(`/clusters/${clusterId}/posts?sortBy=publishingDate:desc${type ?  `&type=${type}`: ''}${categoryId ?  `&categoryId=${categoryId}`: ''}`).then(response => response.data.results)
    }
    ,
    getClusterMembers(clusterId) {
        return apiClient.get(`/clusters/${clusterId}/members?limit=1000`).then(response => response.data.results)
    },
    getCompanyTags(companyId) {
        return apiClient.get(`/tags?companyId=${companyId}&sortBy=Name`).then(response => response.data.results)
    },
    getCompanyCategories(companyId) {
        return apiClient.get(`/categories?companyId=${companyId}&sortBy=Name`).then(response => response.data.results)
    },
    getClusterJoinRequests(clusterId) {
        return apiClient.get(`/clusters/${clusterId}/joinRequests`).then(response => response.data)
    },
    acceptClusterJoinRequest(clusterId, companyId) {
        return apiClient.post(`/clusters/${clusterId}/acceptRequest/${companyId}`).then(response => response.data)
    },
    declineClusterJoinRequest(clusterId, companyId) {
        return apiClient.post(`/clusters/${clusterId}/declineRequest/${companyId}`).then(response => response.data)
    },
    createTag(tagData) {
        return apiClient.post(`/tags`, tagData).then(response => response.data)
    },
    getTags() {
        return apiClient.get(`/tags`).then(response => response.data.results)
    },
    updateTag(tagData) {
        console.log("TagData", tagData)
        const tagId = tagData.id
        const _tagData = { ...tagData }
        delete  _tagData.id
        delete  _tagData.cluster
        return apiClient.patch(`/tags/${tagId}`, _tagData).then(response => response.data)
    },
    deleteTag(tagId) {
        return apiClient.delete(`/tags/${tagId}`).then(response => response.data)
    },
    getClusterTags(clusterId) {
        return apiClient.get(`/clusters/${clusterId}/tags?sortBy=name`).then(response => response.data.results)
    },
    getClusterCategories(clusterId) {
        return apiClient.get(`/clusters/${clusterId}/categories?sortBy=name`).then(response => response.data.results)
    },
    createCategory(tagData) {
        return apiClient.post(`/categories`, tagData).then(response => response.data)
    },
    updateCategory(categoryData) {
        console.log("TagData", categoryData)
        const categoryId = categoryData.id
        const _categoryData = { ...categoryData }
        delete  _categoryData.id
        delete  _categoryData.cluster
        delete _categoryData.hasChildren
        return apiClient.patch(`/categories/${categoryId}`, _categoryData).then(response => response.data)
    },
    deleteCategory(categoryId) {
        return apiClient.delete(`/categories/${categoryId}`).then(response => response.data)
    },
    getCategories() {
        return apiClient.get(`/categories`).then(response => response.data.results)
    },
    getCategory(categoryId) {
        return apiClient.get(`/categories/${categoryId}`).then(response => response.data)
    },
    addClusterMember(clusterId, companyId) {
        console.log("addClusterMember", clusterId, companyId)
        return apiClient.post(`/clusters/${clusterId}/members`, { companyId }).then(response => response.data)
    },
    removeClusterMember(clusterId, companyId) {
        return apiClient.delete(`/clusters/${clusterId}/members/${companyId}`).then(response => response.data)
    },
    upload(file, onUploadProgress, extractZip, description = "") {
        let formData = new FormData();

        formData.append("file", file);
        formData.append("description", description)
        formData.append("unzipContent", extractZip.toString())

        return apiClient.post("/content/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress,
            timeout: 0
        });
    },
}
